<template>
  <div>
    <!-- Article detail information -->
    <base-loader :isLoading="loading"></base-loader>
    <v-container class="mh-100">
      <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
      <v-row v-if="article" justify="center">
        <v-col cols="10">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-img :aspect-ratio="16 / 9" :src="article.headerPicture" height="300"></v-img>
                </v-col>
                <v-col cols="12">
                  <h1 class="my-5">{{ article.title }}</h1>
                  <!-- Article details -->
                  <p v-html="article.content"></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  components: {},
  data() {
    return {
      loading: false,
      crumbs: [
        {
          text: "Articles",
          disabled: false,
          href: "/articles",
        },
        {
          text: "Article Details",
          disabled: true,
        },
      ],
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {},
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
          }
        }
      `,
      fetchPolicy: "network-only",
    },
    article: {
      query: gql`
        query Article($id: ID!) {
          article(id: $id) {
            id
            title
            content
            featurePicture
            headerPicture
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mh-100 {
  min-height: 100vh;
}
</style>
